import { useRestaurant } from "../../context/restaurant-context";
import { IS3Object } from "../../graphql/customQueries";
import { getCloudFrontDomainName } from "../../private/aws-custom";
import { CachedImage } from "../../tabin/components/cachedImage";
import { isMobile } from "react-device-detect";
import { format, isToday } from "date-fns";
import { useLocation, useParams } from "react-router-dom";

export const OrderPlaced = () => {
    const { restaurant } = useRestaurant();

    const location = useLocation();

    const { tableNumber, orderScheduledAt } = location.state;

    console.log("tableNumber ", location.state.tableNumber);

    const RestaurantLogo = (props: { image: IS3Object }) => {
        return (
            <CachedImage
                url={`${getCloudFrontDomainName()}/protected/${props.image.identityPoolId}/${props.image.key}`}
                className="mb-4"
                style={{ width: "150px" }}
                alt="restaurant-logo"
            />
        );
    };

    return (
        <div style={{ padding: isMobile ? "24px" : "64px", textAlign: "center" }}>
            {restaurant && restaurant.logo && <RestaurantLogo image={restaurant.logo} />}
            {tableNumber === null ? (
                <>
                    <div className="h3">Your order is confirmed!</div>
                    <div className="h3 mt-3">Prepare to collect your food from {restaurant && restaurant.name}.</div>
                    {!orderScheduledAt ? (
                        <div className="h2 mt-3">
                            Estimated Pickup Time:{" "}
                            {restaurant && restaurant.preparationTimeInMinutes ? `${restaurant.preparationTimeInMinutes} minutes` : "ASAP"}
                        </div>
                    ) : isToday(new Date(orderScheduledAt)) ? (
                        <div className="h2 mt-3">Estimated Pickup Time: Today, {format(new Date(orderScheduledAt), "h:mm a")}</div>
                    ) : (
                        <div className="h2 mt-3">Estimated Pickup Time: {format(new Date(orderScheduledAt), "do MMM yyyy, h:mm a")}</div>
                    )}

                    <div className="mt-3">You may now close this page.</div>
                </>
            ) : (
                <>
                    <div className="order-placed-success h3">Your order has been successfully forwarded to the kitchen!</div>
                    <div className="order-placed-table-message h3 mt-3">Your food will be served at Table {tableNumber}.</div>
                    <div className="order-placed-close-page mt-3">You may now close this page.</div>
                </>
            )}
        </div>
    );
};
