import { useState, createContext, useContext, useEffect } from "react";

import {
    IGET_RESTAURANT,
    IGET_RESTAURANT_CATEGORY,
    IGET_RESTAURANT_MODIFIER,
    IGET_RESTAURANT_MODIFIER_GROUP,
    IGET_RESTAURANT_PRODUCT,
} from "../graphql/customQueries";
import { getCloudFrontDomainName } from "../private/aws-custom";
import { useGetRestaurantQuery } from "../hooks/useGetRestaurantQuery";

interface IMENU_CATEGORIES {
    [index: string]: IGET_RESTAURANT_CATEGORY;
}

interface IMENU_PRODUCTS {
    [index: string]: IGET_RESTAURANT_PRODUCT;
}

interface IMENU_MODIFIER_GROUPS {
    [index: string]: IGET_RESTAURANT_MODIFIER_GROUP;
}

interface IMENU_MODIFIERS {
    [index: string]: IGET_RESTAURANT_MODIFIER;
}

type ContextProps = {
    restaurant: IGET_RESTAURANT | null;
    setRestaurant: (restaurant: IGET_RESTAURANT) => void;
    restaurantProductImages: any;
    menuCategories: IMENU_CATEGORIES;
    menuProducts: IMENU_PRODUCTS;
    menuModifierGroups: IMENU_MODIFIER_GROUPS;
    menuModifiers: IMENU_MODIFIERS;
};

const RestaurantContext = createContext<ContextProps>({
    restaurant: null,
    setRestaurant: () => {},
    restaurantProductImages: {},
    menuCategories: {},
    menuProducts: {},
    menuModifierGroups: {},
    menuModifiers: {},
});

const RestaurantProvider = (props: { children: React.ReactNode }) => {
    const [restaurant, setRestaurant] = useState<IGET_RESTAURANT | null>(null);
    const [menuCategories, setMenuCategories] = useState<IMENU_CATEGORIES>({});
    const [menuProducts, setMenuProducts] = useState<IMENU_PRODUCTS>({});
    const [menuModifierGroups, setMenuModifierGroups] = useState<IMENU_MODIFIER_GROUPS>({});
    const [menuModifiers, setMenuModifiers] = useState<IMENU_MODIFIERS>({});
    const restaurantProductImages = {};

    useEffect(() => {
        // if (getRestaurantData && getRestaurantData.logo) {
        //     //Don't need to do await here. We do not need the base64 logo here instantly.
        //     getBase64FromUrlImage(
        //         `${getCloudFrontDomainName()}/protected/${getRestaurantData.logo.identityPoolId}/${getRestaurantData.logo.key}`,
        //         250,
        //         "image/png"
        //     )
        //         .then((base64Logo) => setRestaurantBase64Logo(base64Logo))
        //         .catch((e) => console.error("Error getting logo base64", e));
        // } else {
        //     setRestaurantBase64Logo(null);
        // }

        const categories: IMENU_CATEGORIES = {};
        const products: IMENU_PRODUCTS = {};
        const modifierGroups: IMENU_MODIFIER_GROUPS = {};
        const modifiers: IMENU_MODIFIERS = {};

        const processProduct = (product) => {
            if (!products[product.id]) products[product.id] = product;

            if (!product.modifierGroups) return;
            product.modifierGroups.items.forEach((pmgLink) => {
                const modifierGroup = pmgLink.modifierGroup;

                if (!modifierGroups[modifierGroup.id]) modifierGroups[modifierGroup.id] = modifierGroup;

                if (!modifierGroup.modifiers) return;
                modifierGroup.modifiers.items.forEach((mgmLink) => {
                    const modifier = mgmLink.modifier;

                    if (!modifiers[modifier.id]) modifiers[modifier.id] = modifier;

                    if (modifier.productModifier) processProduct(modifier.productModifier);
                });
            });
        };

        if (restaurant) {
            restaurant.categories.items.forEach((category) => {
                if (!categories[category.id]) categories[category.id] = category;

                if (!category.products) return;
                category.products.items.forEach((cpLink) => {
                    processProduct(cpLink.product);
                });
            });

            setMenuCategories(categories);
            setMenuProducts(products);
            setMenuModifierGroups(modifierGroups);
            setMenuModifiers(modifiers);
        }
    }, [restaurant]);

    const _setRestaurant = (newRestaurant: IGET_RESTAURANT | null) => {
        setRestaurant(newRestaurant);
    };

    return (
        <RestaurantContext.Provider
            value={{
                // selectRestaurant: props.selectRestaurant,
                // userRestaurants: props.userRestaurants,
                restaurant: restaurant,
                setRestaurant: _setRestaurant,
                restaurantProductImages: restaurantProductImages,
                // restaurantBase64Logo: restaurantBase64Logo,
                menuCategories: menuCategories,
                menuProducts: menuProducts,
                menuModifierGroups: menuModifierGroups,
                menuModifiers: menuModifiers,
                // isLoading: restaurantLoading,
                // isError: restaurantError,
            }}
            children={
                <>
                    {props.children}
                    {restaurant && restaurant.customStyleSheet && (
                        <link
                            rel="stylesheet"
                            type="text/css"
                            href={`${getCloudFrontDomainName()}/protected/${restaurant.customStyleSheet.identityPoolId}/${
                                restaurant.customStyleSheet.key
                            }`}
                        />
                    )}
                </>
            }
        />
    );
};

const useRestaurant = () => {
    const context = useContext(RestaurantContext);
    if (context === undefined) {
        throw new Error(`useRestaurant must be used within a RestaurantProvider`);
    }
    return context;
};

export { RestaurantProvider, useRestaurant };
